

.modal-header {
  border-bottom: none !important;
  .btn-close {
    margin-left: 0 !important;
  }
}

.modal-wallet {
  .modal-content {
    background-color: #313232;
    color: #fff;
    width: 400px;
    min-width: 400px;
  }
}

.btn-close {
  color: #fff;
}

.modal-wallet{
.modal-title {
  justify-content: center;

  display: flex;
  //   justify-content: space-between;
  align-items: center;
  width: 72%;

  font-family: Outfit, sans-serif;
  font-weight: 700 !important;
  text-transform: uppercase;
  font-size: 22px;
  line-height: 24px;
}
}

.modal-footer {
  border-top: 2px solid #ffffff26 !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.modal-body {
  padding: 18px;
  padding-top: 0;

  .ju367v14 {
    font-size: 16px !important;
    line-height: 18px !important;
    color: rgba(255, 255, 255, 0.5803921569) !important;
    margin-bottom: 15px !important;
  }

  .iekbcc0 {
    border: 0;
    box-sizing: border-box;
    font-size: 100%;
    line-height: normal;
    margin: 0;
    padding: 0;
    text-align: left;
    vertical-align: baseline;
    -webkit-tap-highlight-color: transparent;

    border: 0;
    box-sizing: border-box;

    background: transparent;
    color: white;
    font-family: Outfit, sans-serif;
    font-weight: 700;
  }
}

.ju367v1i {
  gap: 4px;
}

.ju367v10 {
  flex-direction: column;
}
.ju367va {
  display: flex;
}

.connectWallet {
  .parent {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 5px 0;
}

.image-class {
    width: 28px;
    height: 28px;
    border-radius: 5px;
  }
}
