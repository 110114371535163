.cNTngN {
    background: rgb(11, 12, 18);
    // background-image: linear-gradient(to right, #434343 0%, black 100%);
    // background-image: linear-gradient(to right, #0f0c29, #302b63, #24243e);
    // background: linear-gradient(90deg, #a98044 0%, #09760d 100%);
    min-height: 100vh;
    padding: 168px 0px 40px;
    position: relative;
    z-index: 0;

    // ::before {
    // z-index: -1;
    // position: absolute;
    content: "";
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-image: url("../../images/bg/mesh-grad-1-aee64fca.png");
    background-repeat: no-repeat;
    background-position: center top;
    // }

    .banner-title {
        font-weight: 700;
        font-size: 70px;
        line-height: 90px;
        text-align: center;
        color: rgb(255, 255, 255);
    }

    .fw-600 {
        font-weight: 600;
    }

    .ff-outfit {
        font-family: Outfit, sans-serif;
    }

    // ::after {
    //     z-index: -1;
    //     position: absolute;
    //     content: "";
    //     bottom: 0px;
    //     left: 0px;
    //     width: 100%;
    //     height: 100%;
    //     background-image: url("../../images/bg/mesh-grad-2-492bd1d5.png");
    //     background-repeat: no-repeat;
    //     background-position: center bottom;
    // }

    .fOxTEo .count-item:last-child ::before {
        display: none;
    }

    .fOxTEo {
        display: flex;
        align-items: center;
        gap: 25px;

        .count-item:not(:last-child) {
            padding-right: 25px;
        }

        .count-item {
            display: flex;
            align-items: baseline;
            position: relative;

            ::before {
                font-family: Orbitron, sans-serif;
            }

            ::before {
                position: absolute;
                content: ":";
                top: 0px;
                right: -5px;
                font-family: Outfit, sans-serif;
                font-weight: 700;
                font-size: 40px;
                line-height: 60px;
                color: rgba(255, 255, 255, 0.2);
            }
        }

        .count {
            font-family: Orbitron, sans-serif;
        }

        .count {
            font-family: Outfit, sans-serif;
            font-weight: 700;
            font-size: 40px;
            line-height: 60px;
            color: rgb(255, 255, 255);
        }

        .label {
            font-family: Orbitron, sans-serif;
        }

        .label {
            font-family: Orbitron, sans-serif;
        }
        .label {
            font-weight: 700;
            font-size: 24px;
            line-height: 59px;
            color: rgb(255, 255, 255);
        }
    }

    .cAlltz {
        width: 100%;
        height: 40px;
        background: rgba(255, 255, 255, 0.05);
        border: 1px dashed rgba(255, 255, 255, 0.2);
        border-radius: 20px;
        padding: 8px;

        .progress-done {
            height: 100%;
            background: rgb(30, 232, 183);
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        .progress-done {
            width: 100%;
            height: 24px;
            background: rgb(66, 80, 244);
            border-radius: 12px;

            p {
                padding-right: 5px;
                font-weight: 700;
                font-size: 12px;
                line-height: 15px;
                color: rgb(255, 255, 255);
                margin-bottom: 0;
            }
        }
    }

    .iAqwGG {
        backdrop-filter: blur(7.5px);
        border: 0px;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 17px;
        min-width: 270px;
        background: linear-gradient(
            90deg,
            rgb(207 174 36) 0%,
            rgb(6 149 80) 100%
        );
        border-radius: 50px;
        font-weight: 700;
        font-size: 15px;
        line-height: 26px;
        color: rgb(255, 255, 255);
        transition: all 0.3s ease 0s;
    }

    .social-links {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        flex-wrap: wrap;
        list-style: none;

        ul,
        ol {
            list-style: outside none none;
            margin: 0px;
            padding: 0px;
        }

        li a {
            width: 50px;
            height: 50px;
            background: rgba(255, 255, 255, 0.15);
            backdrop-filter: blur(10px);
            border-radius: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s ease 0s;

            :hover {
                opacity: 0.7;
            }
        }
    }
}
