// .btn-close {
//   background: transparent;
// }

// .btn-close:after {
//   content: "x";
//   position: relative;
//   left: -3px;
//   top: -3px;
// }

.modal-buynow {
  color: #fff;

  .modal-content {
    width: 530px;
    max-width: 100%;
    height: auto;
    background: rgb(36, 38, 49);
    border-radius: 20px;
    overflow: hidden;
    padding: 40px;
    position: relative;
    z-index: 0;
    animation: 0.3s ease 0s 1 normal none running modalContentAnimation;

    ::before {
      position: absolute;
      content: "";
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      // background-image: url("../../../images/bg/overlay1-6b4d2b46.png");
      background-repeat: no-repeat;
      background-position: center top;
      z-index: -1;
    }

    .modal-header {
      margin-bottom: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 20px;
      border-bottom: none !important;
      padding: 0;
      .btn-close,
      button {
        color: #fff;
      }
    }

    .modal-body {
      padding: 0;

      h6 {
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 15px;
        line-height: 30px;
        text-transform: uppercase;
        color: rgb(255, 255, 255);
      }

      .input-group {
        width: 100%;
        display: flex;
      }

      .input-group {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: 100%;

        input {
          width: 60%;
        }

        input {
          // width: 100%;
          padding: 16px 16px 15px;
          background: rgba(255, 255, 255, 0.05);
          border: 2px solid rgba(255, 255, 255, 0.1);
          border-radius: 10px;
          font-weight: 600;
          font-size: 18px;
          line-height: 18px;
          color: rgb(255, 255, 255);
          transition: all 0.3s ease 0s;
        }

        :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
            .valid-feedback
          ):not(.invalid-tooltip):not(.invalid-feedback) {
          margin-left: calc(var(--bs-border-width) * -1);
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          background: transparent;
          color: #a7a7a7;
          border: none;
          font-weight: 700;
        }

        .input-group-dropdown {
          width: 40%;

          .efIDXs {
            position: relative;
          }
        }

        button {
          border-radius: 0px 10px 10px 0px;
        }

        .input-group-dropdown {
          width: 40%;
        }

        .dropdown-toggle {
          height: 57px;
          width: 100%;
          padding: 9px 16px;
          background: rgba(255, 255, 255, 0.05);
          border: 2px solid rgba(255, 255, 255, 0.1);
          // border-radius: 10px;
          font-weight: 600;
          font-size: 18px;
          line-height: 18px;
          color: rgb(255, 255, 255);
          display: flex;
          align-items: center;
          gap: 14px;
        }
        .dropdown-toggle::after {
          display: none;
        }
      }

      .presale-item {
        input#usd-amount {
          width: 100%;
          padding: 16px 16px 15px;
          background: rgba(255, 255, 255, 0.05);
          border: 2px solid rgba(255, 255, 255, 0.1);
          border-radius: 10px;
          font-weight: 600;
          font-size: 18px;
          line-height: 18px;
          color: rgb(255, 255, 255);
          transition: all 0.3s ease 0s;
        }
      }

      .token-info-list {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-left: 0;
        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 8px;
          flex-wrap: wrap;
          padding-bottom: 11px;
          border-bottom: 1px dashed rgba(255, 255, 255, 0.1);
        }
      }

      .jyEwHO {
        backdrop-filter: blur(7.5px);
        border: 0px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: 100%;
        padding: 17px;
        min-width: 270px;
        // background: linear-gradient(
        //   90deg,
        //   rgb(224, 95, 187) 0%,
        //   rgb(66, 80, 244) 100%
        // );
        background: linear-gradient(
          90deg,
          rgb(207 174 36) 0%,
          rgb(6 149 80) 100%
        );
        border-radius: 50px;
        font-weight: 700;
        font-size: 15px;
        line-height: 26px;
        color: rgb(255, 255, 255);
        transition: all 0.3s ease 0s;
      }
    } //body
  }
}
