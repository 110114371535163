@import '~bootstrap/scss/bootstrap';



html, body, p, a {
  font-family: Inter, sans-serif;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
}


a {
  outline: none;
  transition: all 0.4s ease 0s;
  text-decoration: none !important;
}

.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-74 {
  margin-bottom: 74px;
}

.text-center {
  text-align: center!important;
}

.fw-600 {
  font-weight: 600;
}

.ff-outfit {
  font-family: Outfit, sans-serif;
}

.fs-15 {
  font-size: 15px;
}

.ff-orbitron {
  font-family: Orbitron, sans-serif;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

// .App {
//   // background-color: #060609;

  
// }

// @media (min-width: 1400px) {
//   .container,
//   .container-lg,
//   .container-md,
//   .container-sm,
//   .container-xl,
//   .container-xxl {
//     max-width: 1170px;
//     padding-left: 0px !important;
//     padding-right: 0px !important;
  
//   }
// }
// @media (min-width: 1400px) {
//   .container {
//     max-width: 1200px;
//     padding: 0px 20px;
//   }
// }





// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }
