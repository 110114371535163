.sc-bczSft.fekrrr.header-section {
  position: absolute;
  z-index: 999;
  top: 0px;
  left: 0px;
  width: 100%;
  padding: 25px 0px;
  transition: all 0.3s ease 0s;

  .gittu-header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .gittu-header-left {
    display: flex;
    align-items: center;
    gap: 60px;
  }
  .gittu-header-right {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .deGHTS {
    position: relative;

    .demo-btn {
      width: 50px;
      height: 50px;
      border: 0px;
      border-radius: 25px;
      background: rgba(255, 255, 255, 0.15);
      backdrop-filter: blur(7.5px);
      font-size: 22px;
      color: rgb(255, 255, 255);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .gittu-header-menu-toggle {
    display: none;

    .menu-toggler {
      border: 0px;
      padding: 0px;
      background: transparent;
      color: rgb(255, 255, 255);
      font-size: 30px;
    }
  }

  //   right

  .gittu-header-right-menu {
    display: flex;
    align-items: center;
    gap: 40px;

    li a {
      font-family: Outfit, sans-serif;
      font-weight: 700;
      font-size: 15px;
      line-height: 26px;
      text-transform: uppercase;
      color: rgb(255, 255, 255);
    }

    ul,
    ol {
      list-style: outside none none;
      margin: 0px;
      padding: 0px;
    }
  }

  .dGfbQV {
    backdrop-filter: blur(7.5px);
    border: 0px;
    font-weight: 700;
    font-size: 15px;
    line-height: 26px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 12px;
    min-width: 185px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50px;
    color: rgb(255, 255, 255);
    transition: all 0.3s ease 0s;
  }
}

.demo-btn:hover .dropdown-demo-list {
  visibility: visible;
  opacity: 1;
  transform: scale(1) translateY(0px);
}

.dropdown-demo-list {
  position: absolute;
  top: calc(100% + 10px);
  left: 0px;
  width: 170px;
  border-radius: 15px;
  background-color: rgb(42, 43, 51);
  padding: 10px 20px;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.75) translateY(-21px);
  transition: all 0.3s ease 0s;
}

@media screen and (max-width: 1199px) {
  .fOxTEo .count {
    font-size: 30px !important;
    line-height: 50px !important;
  }
  .cNTngN .social-links {
    gap: 15px;
  }
}

@media screen and (max-width: 991px) {
  .sc-bczSft.fekrrr.header-section .gittu-header-left {
    gap: 20px;
  }
  .sc-bczSft.fekrrr.header-section .gittu-header-right-menu {
    gap: 20px;
  }
  .gittu-header-menu-toggle {
    display: block !important;
  }

  .gittu-header-menu {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .gittu-header-logo img{
    width: 150px;
  }
  .connect-wallet-btn {
    span {
      display: none;
    }
  }
  .sc-bczSft.fekrrr.header-section .dGfbQV {
    min-width: 110px;
  }
  .cNTngN .banner-title {
    font-size: 40px !important;
    line-height: 60px !important;
  }
}

// Mobile Drawer
.gittu-mobile-menu-content {
  width: 400px;
  height: 100%;
  background: rgb(27, 34, 38);
  padding: 30px;
  animation: 0.4s ease 0s 1 normal none running sidebarAnimation;
  overflow-y: auto;
  transition: width 0.3s ease 0s;

  .mobile-menu-top {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    margin-bottom: 100px;
  }

  .mobile-logo img {
    max-width: 160px;
  }

  .mobile-menu-close {
    border: 0px;
    background: transparent;
    font-size: 20px;
    color: rgb(255, 255, 255);
  }

  .mobile-menu-list {
    text-align: center;
    list-style: none;
    padding-left: 0;
    li a {
      font-family: Outfit, sans-serif;
      font-weight: 500;
      font-size: 18px;
      line-height: 40px;
      color: rgb(255, 255, 255);
      transition: all 0.3s ease 0s;
    }
  }

  .mobile-social-links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    list-style: none;
    padding-left: 0;
    li a {
      width: 40px;
      height: 40px;
      background: rgba(255, 255, 255, 0.15);
      backdrop-filter: blur(10px);
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      transition: all 0.3s ease 0s;
    }
  }

  .connect-wallet-btn {
    width: 100%;
  }

  .dGfbQV {
    backdrop-filter: blur(7.5px);
    border: 0px;
    font-weight: 700;
    font-size: 15px;
    line-height: 26px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 12px;
    min-width: 185px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50px;
    color: rgb(255, 255, 255);
    transition: all 0.3s ease 0s;
  }
}


@media screen and (max-width: 480px) {
  .sc-hKMtDE.deGHTS{
    display: none;
  }
}
